<template>
    <div>
        <div class="header_holder text-center ma">
            <router-link to="/users_edit/0" class="left_btn">
                <v-icon small right>fas fa-user-plus</v-icon>
            </router-link>
            <div class="rounder_top  mb-2">
                <div class="card_header ubuntu_b primer">
                    Korisnici
                </div>
            </div>
        </div>
        <div v-if="users.length">
            <UserCard
                v-for="user in users" :key="user.id" v-bind:user="user"
            ></UserCard>
        </div>
        <div v-if="!users.length" class="pa-6 text-center rounder primer mt-12">
            <router-link to="/users_edit/0" color="black">
                <v-icon small right>fas fa-user-plus</v-icon> trenutno nemate korisnika, želite li dodati novog korisnika?
            </router-link>
        </div>
    </div>
</template>
<script>
import UserCard from './UserCard.vue'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
    components: { UserCard },
    computed: {
        ...mapGetters({
            users: 'users/getUsers',
            userCredentials: 'user/getCredentials',
        })
    },
    methods: {
    },
    created () {
        this.$store.dispatch('users/getAllUsers')
        this.$store.dispatch('users/getPoslovnice')
    },
    beforeCreate(){
        if(this.$store.getters['user/getCredentials'].users == 0) this.$router.push({ name: 'not_auth' })
    }
}
</script>
<style scoped>
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
</style>