<template>
    <div class="order_holder primer rounder mt-12 pt-4 px-4">
        <router-link :to="`/users_edit/`+user.id" class="edit_btn">
            <v-btn class="mx-2" fab small color="primary">
                <v-icon small color="white">fas fa-user-edit</v-icon>
            </v-btn>
        </router-link>
        <div class="header">
            <v-row>
                <v-col cols="6" class="text-left">
                    {{user.title}} (<b>{{ user.username }}</b>)
                </v-col>
                <v-col cols="6" class="text-right">
                    <span>{{ user.created | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY.') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="text-left" @click="changeUserCredentials({ credentials:'narucivanje', user_id:user.id })">
                    <v-icon small class="mr-3">fas fa-{{(user.credentials.narucivanje)?'check':'times'}}</v-icon> Naručivanje
                </v-col>
                <v-col cols="6" class="text-left" @click="changeUserCredentials({ credentials:'reklamacije', user_id:user.id })">
                    <v-icon small class="mr-3">fas fa-{{(user.credentials.reklamacije)?'check':'times'}}</v-icon> Reklamacije
                </v-col>
                <v-col cols="6" class="text-left" @click="changeUserCredentials({ credentials:'useri', user_id:user.id })">
                    <v-icon small class="mr-3">fas fa-{{(user.credentials.useri)?'check':'times'}}</v-icon> Korisnici
                </v-col>
                <v-col cols="6" class="text-left" @click="changeUserCredentials({ credentials:'kosarice', user_id:user.id })">
                    <v-icon small class="mr-3">fas fa-{{(user.credentials.kosarice)?'check':'times'}}</v-icon> Košarice
                </v-col>
            </v-row>
        </div>
        <div class="bottom_order">
            <v-row>
                <v-col cols="6" class="text-left">
                    <v-switch v-model="user.status" label="Aktivan" @click="changeUserStatus(user.id)"></v-switch>
                </v-col>
                <v-col cols="6" class="text-right">
                    {{user.poslovnica}}
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            panel: [],
        }
    },
    props: ['user'],
    computed: {
    },
    methods: {
        ...mapActions('users', [
            'changeUserStatus',
            'changeUserCredentials',
        ]),
    }
}
</script>
<style scoped>
    .text-left .v-input--selection-controls{
        margin-top:0px !important
    }
    .edit_btn {
      position: absolute;
      right: 20px;
      top: -20px;
    }
    .order_holder{
        position:relative;
        box-shadow:0 2px 3px rgba(0,0,0,.07);
        margin-top:20px;
        padding:7px;
    }
    .order_sum {
        float: left !important;
        margin: 10px 90px 10px 10px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    .order_dates{
        background-image:url('/img/strela.png');
        background-position: center center;
        background-repeat: none;
        background-size: 40px;
    }
    .router_right{
        float:right;
        margin-top:10px;
    }
    .order_header{
        width:100%;
        height:60px;
        min-height:60px !important;
        line-height: 60px;
        position:relative;
        padding:10px 10px 10px 60px !important;
        border-bottom:1px solid rgba(0,0,0,.03);
    }
    .product_holder, .package_holder, .bottom_order{
        position:relative;
        width:100%;
        min-height:50px;
        line-height:20px;
        padding:10px 10px 10px 10px;
        border-bottom:1px solid rgb(226, 226, 226);
    }
    .package_holder .product_holder{
        border:none;
        padding:0;
        color:rgb(143, 143, 143);
    }
    .bottom_order{
        height:50px;
        padding-top:15px;
        border:none;
        padding:0 !important;
        border-top:1px solid #000;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(255,255,255,.5);
    }
    .gray_cycle{
        position:absolute;
        left:18px;
        top:18px;
        width:24px;
        height:24px;
        border-radius:50%;
        background:#f7f7f7;
    }
    .item_icon, .bottom_order_icon{
        position:absolute;
        left:4px;
        top:4px;
        width:16px;
        height:16px;
    }
    .bottom_order_icon{
        left:24px;
        top:21px;
        width:24px;
        height:24px;
    }
    .top{
        bottom:unset;
        top:10px;
    }
</style>>